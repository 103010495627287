import React from 'react';
import { PATHS } from 'src/constants/paths';
import MenuItem from './MenuItem';
import './styles.scss';

export interface IMenuItem {
  label: string;
  path?: string;
  subMenus?: {
    label: string;
    path: string;
  }[];
}

const SubMenu: React.FC = () => {
  const renderListMenu = () => {
    const listMenu: IMenuItem[] = [
      {
        label: 'Trade',
        path: PATHS.trade(),
      },
      {
        label: 'Swap',
        path: PATHS.swap(),
      },
      {
        label: 'LP',
        path: PATHS.liquidityPool(),
      },
      {
        label: 'Referrals',
        path: PATHS.referrals(),
      },
      {
        label: 'Points',
        path: PATHS.points(),
      },
    ];

    return listMenu.map((itemMap: IMenuItem, i: number) => {
      return (
        <MenuItem key={i} label={itemMap.label} path={itemMap.path} subMenus={itemMap.subMenus} />
      );
    });
  };

  return <>{renderListMenu()}</>;
};

export default React.memo(SubMenu);
