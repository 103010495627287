export const vaultAbi = [
  {
    inputs: [
      { indexed: false, name: '_owner', internalType: 'address', type: 'address' },
      { indexed: false, name: 'trustedSigner', internalType: 'address', type: 'address' },
    ],
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { inputs: [], name: 'DisputeChallengeFailed', anonymous: false, type: 'error' },
  { inputs: [], name: 'ECRecoverFailed', anonymous: false, type: 'error' },
  { inputs: [], name: 'InvalidAddress', anonymous: false, type: 'error' },
  { inputs: [], name: 'InvalidSP', anonymous: false, type: 'error' },
  { inputs: [], name: 'InvalidSchnorrSignature', anonymous: false, type: 'error' },
  { inputs: [], name: 'InvalidSignature', anonymous: false, type: 'error' },
  { inputs: [], name: 'InvalidUsedSignature', anonymous: false, type: 'error' },
  {
    inputs: [{ indexed: false, name: 'owner', internalType: 'address', type: 'address' }],
    name: 'OwnableInvalidOwner',
    anonymous: false,
    type: 'error',
  },
  {
    inputs: [{ indexed: false, name: 'account', internalType: 'address', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
    anonymous: false,
    type: 'error',
  },
  { inputs: [], name: 'ReentrancyGuardReentrantCall', anonymous: false, type: 'error' },
  { inputs: [], name: 'SettleDisputeFailed', anonymous: false, type: 'error' },
  {
    inputs: [
      { indexed: true, name: 'user', internalType: 'address', type: 'address' },
      { indexed: true, name: 'token', internalType: 'address', type: 'address' },
      { indexed: false, name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'Deposited',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [
      { indexed: false, name: 'requestId', internalType: 'uint32', type: 'uint32' },
      { indexed: true, name: 'user', internalType: 'address', type: 'address' },
    ],
    name: 'DisputeChallenged',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [
      { indexed: false, name: 'requestId', internalType: 'uint32', type: 'uint32' },
      { indexed: true, name: 'user', internalType: 'address', type: 'address' },
    ],
    name: 'DisputeOpened',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [
      { indexed: false, name: 'requestId', internalType: 'uint32', type: 'uint32' },
      { indexed: true, name: 'user', internalType: 'address', type: 'address' },
    ],
    name: 'DisputeSettled',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [
      { indexed: true, name: 'previousOwner', internalType: 'address', type: 'address' },
      { indexed: true, name: 'newOwner', internalType: 'address', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [{ indexed: true, name: 'token', internalType: 'address', type: 'address' }],
    name: 'TokenAdded',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [{ indexed: true, name: 'token', internalType: 'address', type: 'address' }],
    name: 'TokenRemoved',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [
      { indexed: true, name: 'prevSigner', internalType: 'address', type: 'address' },
      { indexed: true, name: 'newSigner', internalType: 'address', type: 'address' },
    ],
    name: 'TrustedSignerChanged',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [
      { indexed: true, name: 'user', internalType: 'address', type: 'address' },
      { indexed: true, name: 'token', internalType: 'address', type: 'address' },
      { indexed: false, name: 'amount', internalType: 'uint256', type: 'uint256' },
      { indexed: false, name: 'requestId', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'WithdrawalRequested',
    anonymous: false,
    type: 'event',
  },
  {
    inputs: [
      { indexed: true, name: 'user', internalType: 'address', type: 'address' },
      { indexed: true, name: 'token', internalType: 'address', type: 'address' },
      { indexed: false, name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'Withdrawn',
    anonymous: false,
    type: 'event',
  },
  {
    outputs: [],
    inputs: [{ indexed: false, name: 'token', internalType: 'address', type: 'address' }],
    name: 'addToken',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    inputs: [
      { indexed: false, name: 'user', internalType: 'address', type: 'address' },
      { indexed: false, name: 'token', internalType: 'address', type: 'address' },
    ],
    name: 'balanceOf',
    anonymous: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [
      { indexed: false, name: 'withdrawalId', internalType: 'uint32', type: 'uint32' },
      {
        components: [
          { name: 'data', internalType: 'bytes', type: 'bytes' },
          { name: 'signature', internalType: 'bytes', type: 'bytes' },
          { name: 'combinedPublicKey', internalType: 'address', type: 'address' },
        ],
        indexed: false,
        name: '_schnorr',
        internalType: 'struct Vault.SchnorrSignature',
        type: 'tuple',
      },
    ],
    name: 'challengeDispute',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    inputs: [],
    name: 'combinedPublicKey',
    anonymous: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    outputs: [
      {
        components: [
          { name: 'schnorrSignatureId', internalType: 'uint32', type: 'uint32' },
          { name: 'signatureFor', internalType: 'address', type: 'address' },
          { name: 'token', internalType: 'address', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'timestamp', internalType: 'uint64', type: 'uint64' },
        ],
        name: '',
        internalType: 'struct Vault.SchnorrData',
        type: 'tuple',
      },
    ],
    inputs: [{ indexed: false, name: '_data', internalType: 'bytes', type: 'bytes' }],
    name: 'decodeSchnorrData',
    anonymous: false,
    stateMutability: 'pure',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [
      { indexed: false, name: 'token', internalType: 'address', type: 'address' },
      { indexed: false, name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'deposit',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    inputs: [{ indexed: false, name: 'token', internalType: 'address', type: 'address' }],
    name: 'isTokenSupported',
    anonymous: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    inputs: [],
    name: 'owner',
    anonymous: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [{ indexed: false, name: 'token', internalType: 'address', type: 'address' }],
    name: 'removeToken',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [],
    name: 'renounceOwnership',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [
      {
        indexed: false,
        name: '_combinedPublicKey',
        internalType: 'address',
        type: 'address',
      },
    ],
    name: 'setCombinedPublicKey',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [{ indexed: false, name: '_expiryTime', internalType: 'uint256', type: 'uint256' }],
    name: 'setSignatureExpiryTime',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [{ indexed: false, name: '_newSigner', internalType: 'address', type: 'address' }],
    name: 'setTrustedSigner',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [
      { indexed: false, name: 'withdrawalId', internalType: 'uint32', type: 'uint32' },
      { indexed: false, name: 'token', internalType: 'address', type: 'address' },
      { indexed: false, name: 'amount', internalType: 'uint256', type: 'uint256' },
      {
        components: [
          { name: 'data', internalType: 'bytes', type: 'bytes' },
          { name: 'signature', internalType: 'bytes', type: 'bytes' },
          { name: 'combinedPublicKey', internalType: 'address', type: 'address' },
        ],
        indexed: false,
        name: '_schnorr',
        internalType: 'struct Vault.SchnorrSignature',
        type: 'tuple',
      },
    ],
    name: 'settleDispute',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    inputs: [],
    name: 'signatureExpiryTime',
    anonymous: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    inputs: [{ indexed: false, name: '_data', internalType: 'bytes', type: 'bytes' }],
    name: 'solidityPackedKeccak256',
    anonymous: false,
    stateMutability: 'pure',
    type: 'function',
  },
  {
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    inputs: [{ indexed: false, name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'supportedTokens',
    anonymous: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [{ indexed: false, name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [
      {
        components: [
          { name: 'trader', internalType: 'address', type: 'address' },
          { name: 'token', internalType: 'address', type: 'address' },
          { name: 'amount', internalType: 'uint256', type: 'uint256' },
          { name: 'timestamp', internalType: 'uint64', type: 'uint64' },
        ],
        indexed: false,
        name: 'withdrawParams',
        internalType: 'struct Vault.WithdrawParams',
        type: 'tuple',
      },
      { indexed: false, name: 'signature', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'withdraw',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    outputs: [],
    inputs: [
      { indexed: false, name: 'token', internalType: 'address', type: 'address' },
      { indexed: false, name: 'amount', internalType: 'uint256', type: 'uint256' },
      {
        indexed: false,
        name: '_combinedPublicKey',
        internalType: 'address',
        type: 'address',
      },
      {
        components: [
          { name: 'data', internalType: 'bytes', type: 'bytes' },
          { name: 'signature', internalType: 'bytes', type: 'bytes' },
          { name: 'combinedPublicKey', internalType: 'address', type: 'address' },
        ],
        indexed: false,
        name: '_schnorr',
        internalType: 'struct Vault.SchnorrSignature',
        type: 'tuple',
      },
    ],
    name: 'withdrawTrustlessly',
    anonymous: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;
