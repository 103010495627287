import { useMutation } from '@tanstack/react-query';
import { ConfigHeaders, apiClient } from 'src/libs/axios';
import { MutationOptions } from 'src/libs/react-query';

export const logout = async () => {
  const response = await apiClient.post<void>(
    '/api/v1/auth/logout',
    {},
    {
      baseURL: process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
      headers: {
        [ConfigHeaders.NO_REFERSH_TOKEN]: true,
        [ConfigHeaders.NO_RELOAD]: true,
      },
    },
  );

  return response.data;
};

export type UseLogoutOptions<TError = Error, TContext = unknown> = MutationOptions<
  void,
  TError,
  void,
  TContext
>;

export const useLogout = <TError = Error, TContext = unknown>(
  options?: UseLogoutOptions<TError, TContext>,
) => {
  return useMutation({
    mutationFn: logout,

    ...options,
  });
};
