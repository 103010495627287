import { MoonOutlined, SettingOutlined, SunOutlined } from '@ant-design/icons';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Button, Dropdown, Flex, Layout } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VirtualIcon } from 'src/assets/icons';
import MenuIcon from 'src/assets/icons/navbar/MenuIcon';
import { PATHS } from 'src/constants/paths';
import { setStorageTheme } from 'src/helpers/storage';
import { useDisclosure } from 'src/hooks/useDisclosure';
import useWindowSize from 'src/hooks/useWindowSize';
import { THEME_MODE } from 'src/interfaces/theme';
import { setTheme } from 'src/store/actions/theme';
import { DepositModal } from './DepositModal';
import SubMenu from './SubMenu';
import './styles.scss';

const { Header } = Layout;

const badgeCountClassName = (count: number) => {
  if (count < 10) return 'count-small';
  if (count >= 10 && count < 100) return 'count-medium';
  if (count >= 100) return 'count-large';
};

interface Props {
  isOpenMenuMobile: boolean;
  setIsOpenMenuMobile: (bool: boolean) => void;
  isOpenNotificationMobile: boolean;
  setIsOpenNotificationMobile: (bool: boolean) => void;
  getCountUnRead: () => void;
  notificationCount: number;
}

const AppHeader: React.FC<Props> = ({
  isOpenMenuMobile,
  setIsOpenMenuMobile,
  isOpenNotificationMobile,
  setIsOpenNotificationMobile,
  getCountUnRead,
  notificationCount,
}) => {
  const { push } = useHistory();
  const isAuth = false;
  const account = useSelector((state: any) => state.currentAccount);
  const userTheme = useSelector((state: any) => state.theme);
  const networkIsWrong = useSelector((state: any) => state.networkIsWrong);
  const { isDesktop, width } = useWindowSize();

  const { myTier } = useSelector((state: any) => ({
    myTier: get(state, 'user.myTier', ''),
  }));
  const [isOpenNotify, setIsOpenNotify] = useState(false);
  const dispatch = useDispatch();

  const userLoginSuccess = false;

  const [notifyDetail, setNotifyDetail] = useState<any>();

  const [depositModalOpen, depositModalActions] = useDisclosure();

  useEffect(() => {
    if (userLoginSuccess && account) {
      getCountUnRead();
    }
    // eventBus.on(SocketEvent.delete_pair, () => {
    //   getCountUnRead();
    // });

    // eslint-disable-next-line
  }, [userLoginSuccess, account]);

  const handleChangeTheme = () => {
    if (userTheme === THEME_MODE.DARK) {
      dispatch(setTheme(THEME_MODE.LIGHT));
      setStorageTheme(THEME_MODE.LIGHT.toLocaleLowerCase());
    } else {
      dispatch(setTheme(THEME_MODE.DARK));
      setStorageTheme(THEME_MODE.DARK.toLocaleLowerCase());
    }
  };

  const renderRightMenuAction = () => {
    return (
      <div className="menu-language-theme">
        <span className="change-theme" onClick={handleChangeTheme}>
          {userTheme === THEME_MODE.DARK ? <SunOutlined /> : <MoonOutlined />}
        </span>

        <span className="change-theme">
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  label: 'Orders',
                  onClick: () => {
                    push('');
                  },
                  key: '1',
                },
                {
                  label: 'History',
                  onClick: () => {
                    push('');
                  },
                  key: '2',
                },
              ],
            }}
          >
            <SettingOutlined />
          </Dropdown>
        </span>
      </div>
    );
  };

  const handleClickLogo = () => {
    push({ pathname: PATHS.default() });
  };

  return (
    <Header className={`site-layout-header ${userTheme}`}>
      <div className="sidebar-wrapper">
        <div className="logo-section" onClick={() => handleClickLogo()}>
          <img src={VirtualIcon} alt="frac logo" />
          <span className="logo-text">
            Virtual<span>DEX</span>
          </span>
        </div>

        <div className="sub-menu-container">{(isDesktop || width > 1024) && <SubMenu />}</div>
      </div>

      {width > 1024 ? (
        <div className="info-section">
          <Flex gap={12} align="center">
            <Button
              size="large"
              type="primary"
              style={{ minWidth: 120, fontWeight: 500 }}
              onClick={depositModalActions.open}
            >
              Deposit
            </Button>
            <ConnectButton />
          </Flex>

          {renderRightMenuAction()}
        </div>
      ) : (
        <span
          className="menu-icon-mobile"
          onClick={() => {
            setIsOpenMenuMobile(!isOpenMenuMobile);
          }}
        >
          <MenuIcon color={'#878787'} isOpen={isOpenMenuMobile} />
        </span>
      )}

      <DepositModal
        open={depositModalOpen}
        onClose={depositModalActions.close}
        onDepositSettle={depositModalActions.close}
      />
    </Header>
  );
};

export default React.memo(AppHeader);
