import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Flex,
  Form,
  InputNumber,
  Modal,
  ModalProps,
  Select,
  Skeleton,
  Space,
  Typography,
  message,
} from 'antd';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo } from 'react';
import { delay } from 'src/helpers';
import { useVaultAddress } from 'src/hooks/useVaultAddress';
import { handleTransactionError } from 'src/libs/wagmi';
import { buildApproveVariables, useApprove } from 'src/services/blockchain/approve';
import {
  UseDepositOptions,
  buildDepositVariables,
  useDeposit,
} from 'src/services/blockchain/deposit';
import { useErc20Allowance } from 'src/services/blockchain/get-allowance';
import { useErc20Balance } from 'src/services/blockchain/get-erc20-balance';
import { Token, useSupportedTokens } from 'src/services/token/get-supported-tokens';
import { userKeys } from 'src/services/user/user-keys';
import { Address, formatUnits, isAddressEqual, parseUnits } from 'viem';
import { useAccount, useWaitForTransactionReceipt } from 'wagmi';

const getActiveToken = (supportedTokens: Token[], activeTokenAddress: Address) => {
  const token = supportedTokens.find(({ address }) => isAddressEqual(address, activeTokenAddress));

  return token;
};

interface FormValues {
  token: Address;
  amount: number;
}

type DepositModalProps = Pick<ModalProps, 'open' | 'onClose'> & {
  onDepositSuccess?: NonNullable<UseDepositOptions<unknown>['mutation']>['onSuccess'];
  onDepositError?: NonNullable<UseDepositOptions<unknown>['mutation']>['onError'];
  onDepositSettle?: () => void;
};

export const DepositModal = ({
  open,
  onClose,
  onDepositError,
  onDepositSuccess,
  onDepositSettle,
}: DepositModalProps) => {
  const queryClient = useQueryClient();

  const { address: userAddress } = useAccount();

  const supportedTokensQuery = useSupportedTokens();
  console.log('🚀 ~ supportedTokensQuery:', supportedTokensQuery.data);

  const [form] = Form.useForm();
  const initialSelectedToken = supportedTokensQuery.data?.at?.(0)?.address;
  const selectedTokenAddress = Form.useWatch('token', form) ?? initialSelectedToken;
  const depositAmount = Form.useWatch('amount', form);

  const erc20BalanceQuery = useErc20Balance({ tokenAddress: selectedTokenAddress });

  const vaultAddress = useVaultAddress();

  const allowanceQuery = useErc20Allowance({
    tokenAddress: selectedTokenAddress,
    contractAddress: vaultAddress,
  });

  const approveMutation = useApprove();
  const waitForApproveReceiptQuery = useWaitForTransactionReceipt({
    hash: approveMutation.data,
    confirmations: Number(process.env.REACT_APP_BLOCK_CONFIRMATION!),
    query: {
      enabled: !!approveMutation.data,
    },
  });

  const refetchAllowance = allowanceQuery.refetch;
  useEffect(() => {
    if (waitForApproveReceiptQuery.status !== 'pending') {
      refetchAllowance();
    }
  }, [waitForApproveReceiptQuery.status, refetchAllowance]);

  const handleApprove = () =>
    approveMutation.writeContract(
      buildApproveVariables({
        amount: parseUnits(depositAmount.toString(), 18),
        tokenAddress: selectedTokenAddress,
        vaultAddress,
      }),
      {
        onError: handleTransactionError(() => message.error('Approve failed')),
      },
    );

  const depositMutation = useDeposit();
  const waitForDepositReceiptQuery = useWaitForTransactionReceipt({
    hash: depositMutation.data,
    confirmations: Number(process.env.REACT_APP_BLOCK_CONFIRMATION!),
    query: {
      enabled: !!depositMutation.data,
    },
  });

  const refetchErc20Balance = allowanceQuery.refetch;

  useEffect(() => {
    if (waitForDepositReceiptQuery.status === 'pending') {
      return;
    }

    (async () => {
      onDepositSettle?.();

      await delay(2000);
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: userKeys.balances(userAddress!) }),
        refetchErc20Balance(),
      ]);
    })();
  }, [waitForDepositReceiptQuery.status, refetchErc20Balance]);

  const activeToken = useMemo(
    () => getActiveToken(supportedTokensQuery.data ?? [], selectedTokenAddress),
    [supportedTokensQuery.data, selectedTokenAddress],
  );

  const handleSubmit = ({ amount, token }: FormValues) => {
    const parsedAmount = parseUnits(amount.toString(), activeToken?.decimal ?? 18);

    depositMutation.writeContract(
      buildDepositVariables({
        amount: parsedAmount,
        tokenAddress: token,
        vaultAddress,
      }),
      {
        onSuccess: onDepositSuccess,
        onError: onDepositError,
      },
    );
  };

  const renderFooter: ModalProps['footer'] = (_, { CancelBtn, OkBtn }) => {
    if (allowanceQuery.isPending) {
      return (
        <Space>
          <Skeleton.Button active />
          <Skeleton.Button active />
        </Space>
      );
    }

    const _depositAmount = new BigNumber(depositAmount).multipliedBy(
      Math.pow(10, activeToken?.decimal ?? 18),
    );
    const allowanceSufficient = new BigNumber(
      allowanceQuery.data?.toString() ?? 0,
    ).isGreaterThanOrEqualTo(_depositAmount);

    if (allowanceSufficient) {
      return (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      );
    }

    return (
      <Button
        loading={
          approveMutation.isPending ||
          waitForApproveReceiptQuery.isFetching ||
          allowanceQuery.isFetching
        }
        onClick={handleApprove}
        type="primary"
      >
        Approve
      </Button>
    );
  };

  return (
    <Modal
      title="Deposit"
      open={open}
      onClose={onClose}
      onCancel={onClose}
      okText={'Confirm Deposit'}
      footer={renderFooter}
      okButtonProps={{ htmlType: 'submit' }}
      confirmLoading={depositMutation.isPending || waitForDepositReceiptQuery.isFetching}
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="deposit_form"
          initialValues={{
            token: initialSelectedToken,
          }}
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item label="Asset" name="token">
        <Select>
          {supportedTokensQuery.data?.map(({ address, token }) => (
            <Select.Option value={address} key={address}>
              {token}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Amount" name="amount">
        <InputNumber addonAfter={<Button type="link">Max</Button>} style={{ width: '100%' }} />
      </Form.Item>

      <Flex justify="space-between" style={{ marginBottom: 24 }}>
        <Typography>Available</Typography>
        <Typography>
          {erc20BalanceQuery.data !== undefined
            ? formatUnits(erc20BalanceQuery.data, activeToken?.decimal ?? 18)
            : null}{' '}
          {activeToken?.token}
        </Typography>
      </Flex>

      <Typography.Link>See deposit history</Typography.Link>
    </Modal>
  );
};
