import Icon from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ArrowLeftLanguageIconComponent,
  AssetRedemptionIconComponent,
  MyFNFTSIconComponent,
  MyIAOHistoryIconComponent,
  MyNFTSIconComponent,
  ProfileIconVer2Component,
  SettingIconVer2Component,
  SignOutIconComponent,
} from 'src/assets/icons/IconComponent';
import { PATHS } from 'src/constants/paths';
import './mobile-menu-detail-content.scss';

export interface IWalletDetailContent {
  onClose: () => void;
  onDisconnect: () => void;
}

const AccountContent: React.FC<IWalletDetailContent> = ({ onClose, onDisconnect }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userTheme = useSelector((state: any) => state.theme);

  const [isOpenDisconnect, setIsOpenDisconnect] = useState<boolean>(false);

  const handleDisconnect = () => {
    history.push(PATHS.trade());
  };

  const renderContentWithAuth = () => {
    return (
      <div className={`mobile-menu-detail ${userTheme}`}>
        <div className="mobile-menu-detail__item-wrapper default-item pt-20">
          <div
            className="mobile-menu-detail__item-wrapper__item justify-flex-start"
            onClick={onClose}
          >
            <Icon className="back-icon" component={ArrowLeftLanguageIconComponent} />
            <span>{'Account'}</span>
          </div>
        </div>

        <div className="mobile-menu-detail__item-wrapper">
          <a
            className="mobile-menu-detail__item-wrapper__item default-item justify-flex-start"
            href={''}
          >
            <Icon className="item-icon" component={ProfileIconVer2Component} />
            <span>{'Profile'}</span>
          </a>
        </div>

        <div className="line"></div>

        <div className="mobile-menu-detail__item-wrapper">
          <a
            className="mobile-menu-detail__item-wrapper__item default-item justify-flex-start"
            href={''}
          >
            <Icon className="item-icon" component={SettingIconVer2Component} />
            <span>Settings</span>
          </a>
        </div>

        <div className="mobile-menu-detail__item-wrapper">
          <div
            className="mobile-menu-detail__item-wrapper__item default-item justify-flex-start"
            onClick={() => setIsOpenDisconnect(true)}
          >
            <Icon className="item-icon" component={SignOutIconComponent} />
            <span>Disconnect</span>
          </div>
        </div>
      </div>
    );
  };

  return <>{renderContentWithAuth()}</>;
};

export default AccountContent;
