import { GetTokenPriceParams } from './get-token-price';

export const tokenKeys = {
  all: ['token'] as const,

  supportedPairs: () => [...tokenKeys.all, 'supportedPairs'] as const,
  networkSupportedPairs: (network: number) => [...tokenKeys.supportedPairs(), network] as const,

  prices: () => [...tokenKeys.all, 'price'] as const,
  price: (params: GetTokenPriceParams) => [...tokenKeys.prices(), params] as const,
};
