import axios from 'axios';
import qs from 'qs';

import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  removeAuthTokens,
  setAuthTokens,
} from 'src/helpers/storage';
import { refreshTokens } from 'src/services/auth/refresh-token';
import { SuccessResponse } from 'src/types/api-response';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
});

export enum ConfigHeaders {
  /**
   * Do not automatically attach bearer token to header
   */
  NO_BEARER = 'x-no-auto-attach-bearer',
  /**
   * Do not automatically refresh token if an 401 error is thrown
   */
  NO_REFERSH_TOKEN = 'x-no-auto-refresh-token',
  /**
   * Do not automatically reload the site if user is unauthorized (meaning both access and refresh token are invalid)
   */
  NO_RELOAD = 'x-no-auto-reload',
}

apiClient.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  const absentSkipBearerAttachmentHeader =
    Boolean(config.headers[ConfigHeaders.NO_BEARER]) === false;
  const shouldAttachBearer = absentSkipBearerAttachmentHeader && accessToken;

  if (shouldAttachBearer) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

const handleUnauthorizedError = (shouldReload: boolean, error: AxiosError) => {
  removeAuthTokens();

  if (shouldReload) {
    return location.reload();
  }

  return Promise.reject(error);
};

apiClient.interceptors.response.use(
  function (response: AxiosResponse<SuccessResponse, unknown>) {
    return {
      ...response,
      data: response.data.data,
    } satisfies AxiosResponse;
  },
  async function (error: AxiosError) {
    const refreshToken = getRefreshToken();
    const accessToken = getAccessToken();

    const isUnauthorizedError = error.response?.status === 401;
    const hasSkipRefreshTokenHeader = Boolean(error.config?.headers?.[ConfigHeaders.NO_BEARER]);
    const shouldSkipRefreshToken = hasSkipRefreshTokenHeader || !refreshToken || !accessToken;

    const shouldReload = Boolean(error.config?.headers?.[ConfigHeaders.NO_RELOAD]) === false;

    if (!isUnauthorizedError) {
      return Promise.reject(error);
    }

    if (shouldSkipRefreshToken) {
      return handleUnauthorizedError(shouldReload, error);
    }

    try {
      const authTokens = await refreshTokens({ accessToken, refreshToken });
      setAuthTokens(authTokens);

      return apiClient.request(error.config as AxiosRequestConfig<unknown>);
    } catch (_) {
      return handleUnauthorizedError(shouldReload, error);
    }
  },
);
