import { Address, isAddressEqual } from 'viem';
import { GetSupportedPairsResponse, useGetSupportedPairs } from './get-supported-pairs';

export interface Token {
  token: string;
  address: Address;
  decimal: number;
}

const reduceToTokens = (supportedPairs: GetSupportedPairsResponse) => {
  console.log('supportedpairs', supportedPairs);

  return supportedPairs.reduce(
    (
      tokens,
      {
        baseToken,
        baseTokenAddress,
        baseTokenDecimal,
        quoteToken,
        quoteTokenAddress,
        quoteTokenDecimal,
      },
    ) => {
      const _baseToken = tokens.find((token) => isAddressEqual(token.address, baseTokenAddress));

      const pairs: Token[] = [
        {
          address: baseTokenAddress,
          decimal: baseTokenDecimal,
          token: baseToken,
        },
        {
          address: quoteTokenAddress,
          decimal: quoteTokenDecimal,
          token: quoteToken,
        },
      ];

      return pairs;
    },
    [] as Token[],
  );
};

export const useSupportedTokens = () => {
  return useGetSupportedPairs({
    select: reduceToTokens,
  });
};
