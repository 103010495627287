import Icon from '@ant-design/icons';
import { Button, Flex, Menu } from 'antd';
import axios from 'axios';
import { get } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { DarkModeIcon, LightModeIcon, NetworkIcon } from 'src/assets/icons';
import { ArrowRightLanguageIconComponent } from 'src/assets/icons/IconComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import { USER_ROLE } from 'src/constants/formatters';
import { setStorageTheme } from 'src/helpers/storage';
import { THEME_MODE } from 'src/interfaces/theme';
import { setTheme } from 'src/store/actions/theme';
import { MyTier, pickTierColor } from '../constants';
import AccountContent from './AccountContent';
import './mobile-menu-detail-content.scss';
import WalletContent from './WalletContent';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { PATHS } from 'src/constants/paths';
import { MenuItemType } from 'antd/es/menu/interface';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { DepositModal } from '../DepositModal';

interface IWalletDetail {
  address: string;
  onClickItem: () => void;
  isOpen: boolean;
}

const MobileMenuDetail: React.FC<IWalletDetail> = ({ address, onClickItem, isOpen }) => {
  const isAuth = false;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const userTheme = useSelector((state: any) => state.theme);
  const account = useSelector((state: any) => state.currentAccount);
  const [isOpenWallet, setIsOpenWallet] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);

  const [depositModalOpen, depositModalActions] = useDisclosure();

  const handleChangeTheme = () => {
    if (userTheme === THEME_MODE.DARK) {
      dispatch(setTheme(THEME_MODE.LIGHT));
      setStorageTheme(THEME_MODE.LIGHT.toLocaleLowerCase());
    } else {
      dispatch(setTheme(THEME_MODE.DARK));
      setStorageTheme(THEME_MODE.DARK.toLocaleLowerCase());
    }
  };

  const handleToggleWalletContent = () => {
    setIsOpenWallet(!isOpenWallet);
  };

  const handleToggleAccountContent = () => {
    setIsOpenAccount(!isOpenAccount);
  };

  const handleDisconnect = () => {
    setIsOpenAccount(false);
    onClickItem();
  };

  useEffect(() => {
    if (!isOpen) {
      setIsOpenWallet(false);
      setIsOpenAccount(false);
    }
  }, [isOpen]);

  const ListMenuAndSub = useMemo(
    () => [
      {
        label: 'Trade',
        className: 'exchange-icon',
        key: PATHS.trade(),
      },
      {
        label: 'Swap',
        className: 'exchange-icon',
        key: PATHS.swap(),
      },
      {
        label: 'LP',
        className: 'exchange-icon',
        key: PATHS.liquidityPool(),
      },
      {
        label: 'Referrals',
        className: 'exchange-icon',
        key: PATHS.referrals(),
      },
      {
        label: 'Points',
        className: 'exchange-icon',
        key: PATHS.points(),
      },
    ],
    [],
  );

  const renderMenus = useMemo<MenuItemType[]>(() => {
    return ListMenuAndSub.map((item) => {
      return {
        label: item.label,
        key: item.key,
        className: item.className,
      };
    });
  }, [ListMenuAndSub]);

  if (isOpenWallet) {
    return (
      <WalletContent
        address={address}
        onClose={handleToggleWalletContent}
        onClickItem={() => {
          onClickItem();
          setIsOpenWallet(false);
        }}
      />
    );
  }

  if (isOpenAccount) {
    return <AccountContent onClose={handleToggleAccountContent} onDisconnect={handleDisconnect} />;
  }

  return (
    <div className={`mobile-menu-detail ${userTheme}`}>
      <div className="mobile-menu-detail__item-wrapper">
        <div className="mobile-menu-detail__item-wrapper__item justify-between">
          <div>
            <span>{'Wallet'}</span>
          </div>
          <Flex gap={12}>
            <Button
              size="large"
              type="primary"
              style={{ minWidth: 120 }}
              onClick={depositModalActions.open}
            >
              Deposit
            </Button>
            <ConnectButton />
          </Flex>
        </div>
      </div>
      <div className="line" style={{ marginBottom: 10 }}></div>
      <Menu
        mode="inline"
        items={renderMenus}
        onClick={(data) => {
          push(data.key);
          onClickItem();
        }}
      />
      <div className="line"></div>
      <div className="mobile-menu-detail__item-wrapper"></div>

      <div className="mobile-menu-detail__item-wrapper mb-26">
        <div className="mobile-menu-detail__item-wrapper__item justify-between">
          <div>
            <span>{'Appearance'}</span>
          </div>
          <div className="dark-light-mode">
            <span>{userTheme === THEME_MODE.DARK ? 'Dark' : 'Light'}</span>
            <img
              src={userTheme === THEME_MODE.DARK ? DarkModeIcon : LightModeIcon}
              alt="icon"
              onClick={handleChangeTheme}
            />
          </div>
        </div>
      </div>

      <div className="line"></div>

      {/* <div className="mobile-menu-detail__item-wrapper">
        <div className="mobile-menu-detail__item-wrapper__item">
          <div className={`network ${networkIsWrong ? 'warning-network' : ''}`}>
            <img src={NetworkIcon} alt="icon" />
            <span>{networkIsWrong ? 'Wrong Network' : ' BSC'}</span>
          </div>
        </div>
      </div> */}

      {isAuth && account && (
        <>
          <div className="mobile-menu-detail__item-wrapper">
            <div
              className="mobile-menu-detail__item-wrapper__item justify-between"
              onClick={handleToggleWalletContent}
            >
              <div>
                <span>{'Wallet'}</span>
              </div>
              <Icon className="" component={ArrowRightLanguageIconComponent} />
            </div>
          </div>
        </>
      )}

      <DepositModal
        open={depositModalOpen}
        onClose={depositModalActions.close}
        onDepositSettle={depositModalActions.close}
      />
    </div>
  );
};

export default MobileMenuDetail;
