import { Dropdown, Menu, Space } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ArrowDownMenuIcon } from 'src/assets/icons';
import { IMenuItem } from '.';
import { ItemType } from 'antd/es/menu/interface';

const MenuItem: React.FC<IMenuItem> = ({ label, path, subMenus }) => {
  // const { pathname } = useLocation();
  const userTheme = useSelector((state: any) => state.theme);
  const { push } = useHistory();

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  let listSubMenu: ItemType[] = [];

  // const isActiveLink = !!subMenus?.find((subMenuItem, i) =>
  //   pathname.includes(subMenuItem.path ?? ''),
  // );

  const renderMenuItem = () => {
    if (subMenus) {
      listSubMenu = subMenus.map((subItem, i) => {
        const isOpenBlankTab = false;

        const renderLabel = () => {
          if (isOpenBlankTab) {
            return (
              <div
                onClick={() => {
                  window.location.replace(subItem.path ?? '');
                }}
              >
                {subItem.label}
              </div>
            );
          }

          return <Link to={subItem.path}>{subItem.label}</Link>;
        };

        return {
          key: i,
          label: renderLabel(),
        };
      });

      return (
        <Dropdown
          open={isOpenDropdown}
          onOpenChange={(open) => setIsOpenDropdown(open)}
          overlay={<Menu items={listSubMenu} />}
          overlayClassName={`dropdown ${userTheme}`}
          placement="bottom"
        >
          <Space>
            <span className="menu-item__label">{label}</span>
            <img src={ArrowDownMenuIcon} alt="" />
          </Space>
        </Dropdown>
      );
    }

    return (
      <div
        className="menu-item__label"
        onClick={() => {
          push(path ?? '');
        }}
      >
        {label}
      </div>
    );
  };

  return (
    // <div className={`menu-item ${isActiveLink ? 'menu-item--active' : ''}`}>{renderMenuItem()}</div>
    <div className={`menu-item ${isOpenDropdown ? 'menu-item--active' : ''}`}>
      {renderMenuItem()}
    </div>
  );
};

export default MenuItem;
