import { useQueryClient } from '@tanstack/react-query';
import { CommonVariables, WriteContractVariables } from 'src/libs/wagmi';
import { Address, erc20Abi } from 'viem';
import { Config, UseWriteContractParameters, useAccount, useWriteContract } from 'wagmi';
import { readContractQueryKey } from 'wagmi/query';
import { erc20AllowanceQueryOptions } from './get-allowance';
import { useVaultAddress } from 'src/hooks/useVaultAddress';

const commonVariables = {
  abi: erc20Abi,
  functionName: 'approve',
} satisfies CommonVariables<typeof erc20Abi, 'approve'>;

export interface ApproveVariables {
  tokenAddress: Address;
  amount: bigint;
  vaultAddress: Address;
}

export const buildApproveVariables = ({
  tokenAddress,
  amount,
  vaultAddress,
}: ApproveVariables): WriteContractVariables<typeof erc20Abi, 'approve'> => {
  return {
    ...commonVariables,
    address: tokenAddress,
    args: [vaultAddress, amount],
  };
};

export type UseApproveOptions<TContext> = UseWriteContractParameters<Config, TContext>;

export const useApprove = <TContext>({
  mutation,
  ...options
}: UseApproveOptions<TContext> = {}) => {
  const queryClient = useQueryClient();
  const { address: userAddress } = useAccount();
  const vaultAddress = useVaultAddress();

  const approveMutation = useWriteContract({
    mutation: {
      onSuccess: (_, { address }) => {
        const allowanceQueryKey = readContractQueryKey(
          erc20AllowanceQueryOptions({
            tokenAddress: address,
            userAddress: userAddress!,
            contractAddress: vaultAddress,
          }),
        );

        return queryClient.invalidateQueries({ queryKey: allowanceQueryKey });
      },

      ...mutation,
    },
    ...options,
  });

  return approveMutation;
};
