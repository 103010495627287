import { IS_STABLE_COIN } from 'src/constants/exchange';

export const ellipseAddress = (
  address = '',
  maxCharacters = 5,
  maxLastCharacters?: number | undefined,
): string => {
  if (!address) return '';

  return `${address.slice(0, maxCharacters)}...${address.slice(
    -(maxLastCharacters ? maxLastCharacters : maxCharacters),
  )}`;
};

export const decimalCount = (number: any) => {
  const numberAsString = number?.toString();
  if (numberAsString?.includes('.')) {
    return numberAsString?.split('.')?.[1]?.length;
  }
  return 0;
};

export const isStableCoin = (isFNFTOrCoin: any) => {
  return isFNFTOrCoin === IS_STABLE_COIN;
  // return coin?.toUpperCase().includes(STABLE_COINS.USD || STABLE_COINS.USDT || STABLE_COINS.BUSD);
};

export const delay = (timer: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timer);
  });
