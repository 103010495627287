// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.flex {
  display: flex;
}

.full-width {
  width: 100%;
}

.ant-tabs-tabpane {
  outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html *::-webkit-scrollbar {
  border-radius: 0px;
  width: 7px;
  height: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color_border_common);
}

html *::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: var(--color_background);
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,oBAAA;AACF;;AAEA;EACE;IACE,4CAAA;EACF;AACF;AAEA;EACE,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,YAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE;IACE,uBAAA;EAAF;EAEA;IACE,yBAAA;EAAF;AACF;AAGA;EACE,aAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,wBAAA;AADF;;AAIA,gCAAA;AACA;;EAEE,wBAAA;EACA,SAAA;AADF;;AAIA,YAAA;AACA;EACE,0BAAA;AADF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;AADF;;AAGA;EACE,kBAAA;EACA,4CAAA;AAAF;;AAEA;EACE,kBAAA;EACA,yCAAA;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.flex {\n  display: flex;\n}\n\n.full-width {\n  width: 100%;\n}\n\n.ant-tabs-tabpane {\n  outline: none !important;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type='number'] {\n  -moz-appearance: textfield;\n}\n\nhtml *::-webkit-scrollbar {\n  border-radius: 0px;\n  width: 7px;\n  height: 8px;\n}\nhtml *::-webkit-scrollbar-thumb {\n  border-radius: 4px;\n  background-color: var(--color_border_common);\n}\nhtml *::-webkit-scrollbar-track {\n  border-radius: 4px;\n  background-color: var(--color_background);\n}\n\n// check"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
